<template>

    <swiper-slide class="h-full w-full " style="width: 90%">

        <div class="h-full w-full">

            <loader :loading="loading" />

            <div class="h-full w-full rounded-lg module p-2 flex flex-col overflow-hidden">

                <div class="h-8 flex flex-row justify-start items-center">
                    <span class="text-sm font-semibold text-font-gray mr-auto">SMU</span>
                    <!-- <i class="mdi mdi-filter-variant mr-2"></i>
                    <multiselect class="w-32" :multiple="false" v-model="sortBy" label="name"
                        :options="optionsFilter"
                        /> -->
                </div>

                <div class="h-auto w-full mb-1">

                    <div class="h-full w-full flex flex-wrap overflow-x-auto no-scrollbar" v-if="legendColors">

                        <div class="flex-none " v-for="(pill, k) in legendColors" :key="k">

                            <div @click="toggleSlSelectedItems(k)" class="p-1 m-2px text-3xs flex flex-col justify-center items-center px-1 rounded-md mr-1" :style="getOpacity(pill, k)">

                                <span class="text-center">{{ pill.name }}</span>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="flex flex-1 min-h-0 flex-col overflow-auto no-scrollbar pb-6">

                    <div v-for="(el, index) in orderBy" :key="index" v-if="filtered(el.id_sl)" class="h-32 box mt-2 flex flex-row">

                        <div  class="h-full w-1 rounded-full mr-2" :style="'background-color:'+legendColors[el.id_sl].color"></div>

                        <div class="h-full w-full flex flex-col">

                            <div class="h-6 flex flex-row justify-start items-center">
                                <span class="text-white font-semibold text-xs mr-auto">{{ el.name }}</span>
                            </div>

                            <div class="h-6 flex flex-row justify-center items-center">

                                <div class="h-1 w-1 rounded-full shadow-md bg-orange mr-1"></div>

                                <span class="text-3xs mr-2 text-font-gray">Purchased</span>

                                <span class="text-3xs mr-2 font-semibold">{{el.hour_purchased | numberFormat(0)}} h</span>

                                <div class="h-1 w-1 rounded-full shadow-md bg-blue mr-1"></div>

                                <span class="text-3xs mr-2 text-font-gray">Sold</span>

                                <span class="text-3xs font-semibold">{{el.hour_sold | numberFormat(0)}} h</span>

                            </div>

                            <div class="flex-1 min-h-0 pt-1">
                                    <ChartColCompareSmallMobile chartId="chart" colors="purcashed"
                                        :data="[el.amount_purchased, el.amount_sold]" />
                            </div>

                        </div>

                    </div>

                    <div v-else-if="smuElements.length === 0" class="h-full flex flex-col justify-center items-center">

                        <span class="module-title text-font-gray">No data</span>

                    </div>

                    <div v-if="slSelectedItems.length === 0" class="h-full flex flex-col justify-center items-center">

                        <span class="module-title text-font-gray text-xs">No SL Selected</span>

                    </div>

                </div>

            </div>

        </div>

    </swiper-slide>

</template>

<script>
import { state, actions } from '@/store';

export default {
    data() {
        return {
            filter: null,
            sortBy: { name: 'Name', value: 'name' },
            loading: false,
            smuElements: false,
            optionsFilter: this.$optionsFilterInterIntra
        }
    },
    methods: {
        filtered(id){ 
            return this.slSelectedItems.includes(id) 
        },
        getOpacity(pill, k) {
            if (this.slSelectedItems.includes(k)) {
                return 'color:' + pill.color + '; background-color:' + pill.color + '15;'
            } else {
                return 'display:none'
            }
        },
        toggleSlSelectedItems(item){ 
            ctions.toggleSlSelectedItems(item) 
        },
        preload() {
            this.loading = true
        },
        load() {
            this.axios.get('inter_intra/competency', { params: this.params }).then(response => {
                this.smuElements = response.data.data
                this.loading = false
            })
        }
    },
    computed:{
        orderBy() {
            if (this.sortBy) {
                return this._.orderBy(this.smuElements, [this.sortBy.value]) 
            }
        },
        params(){ 
            return state.globalParams 
        },
        slSelectedItems(){ 
            return state.slSelectedItems 
        },
        legendColors(){ 
            return state.slLegendColors 
        },

    },
    watch: {
        activeOption(n, o){ 
            this.$emit('options', n) 
        },
        params(){ 
            this.load() 
        }
    },
    mounted() {
        this.preload();
        if(this.params) {
            this.load();
        }
    }
}
</script>